import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ImageIcons } from '../components/imageComponent';
import Layout from '../components/shared/Layout';


const Profile = () => {
  const [activeTab, setActiveTab] = useState(0); // Default to first tab

  const [image, setImage] = useState<string | null>(null);
  const [imageName, setImageName] = useState("");

  // Handle file input change
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) return;
    const file = files[0];
    if (file) {
      const imageURL = URL.createObjectURL(file);
      setImage(imageURL);
      setImageName(file.name); // Set the uploaded image name
    }
  };
  return (
    <>
      <Layout>
        <div className='pt-[20px] flex flex-col justify-between'>
          <div>
            <div className="mb-[30px]">
              <button
                className={` text-[20px] text-[#717B8C] font-medium border-b-[2px] border-[#E0E4EC] px-[12px] py-[10px] mr-[12px] ${activeTab === 0 ? "!text-[#007AFF] !font-bold !border-[#007AFF]" : ""}`}
                onClick={() => setActiveTab(0)}
              >
                Account Settings
              </button>
              <button
                className={` text-[20px] text-[#717B8C] font-medium border-b-[2px] border-[#E0E4EC] px-[12px] py-[10px] mr-[12px] ${activeTab === 1 ? "!text-[#007AFF] !font-bold !border-[#007AFF]" : ""}`}
                onClick={() => setActiveTab(1)}
              >
                Login and Security
              </button>
              <button
                className={` text-[20px] text-[#717B8C] font-medium border-b-[2px] border-[#E0E4EC] px-[12px] py-[10px] mr-[12px] ${activeTab === 2 ? "!text-[#007AFF] !font-bold !border-[#007AFF]" : ""}`}
                onClick={() => setActiveTab(2)}
              >
                Notifications
              </button>
            </div>

            <div className="mb-[20px]">
              {activeTab === 0 &&
                <div className='main_box'>
                  <div className="mb-[45px]">
                    <label htmlFor="file-input" className="relative w-[130px] h-[130px] border border-dashed border-[#4C535F] rounded-lg flex justify-center items-center text-center text-[#4C535F] cursor-pointer bg-[#EDF2F6]">
                      {image ? (
                        <img src={image} alt="Profile" className="w-full h-full object-cover rounded-lg" />
                      ) : (
                        <div className="max-w-[100px]">
                          <img src={ImageIcons.imgUpload} alt='Upload' className='mx-auto mb-[15px]' />
                          <span className="text-[12px] text-[#4C535F] font-medium leading-[16px] inline-block">Upload your photo</span>
                        </div>
                      )}
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                    />
                    {imageName && (
                      <p className="mt-4 text-sm text-gray-700">
                        <strong>File name:</strong> {imageName}
                      </p>
                    )}
                  </div>

                  <div className='grid grid-cols-2 gap-[25px]'>
                    <div>
                      <label className="block text-[16px] font-medium text-[#4C535F]">Full Name</label>
                      <input
                        type="text"
                        className="px-[15px] h-[52px] w-full border border-[#E0E4EC]  bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Please enter your full name"
                      />
                    </div>

                    <div>
                      <label className="block text-[16px] font-medium text-[#4C535F]">Email</label>
                      <input
                        type="email"
                        className="px-[15px] h-[52px] w-full border border-[#E0E4EC]  bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Please enter your email"
                      />
                    </div>

                    <div>
                      <label className="block text-[16px] font-medium text-[#4C535F]">Username</label>
                      <input
                        type="text"
                        className="px-[15px] h-[52px] w-full border border-[#E0E4EC]  bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                        placeholder="Please enter your username"
                      />
                    </div>

                    <div>
                      <label className="block text-[16px] font-medium text-[#4C535F]">Phone Number</label>
                      <div className="flex">
                        <input
                          type="text"
                          className="px-[15px] h-[52px] w-1/4 border border-[#E0E4EC]  bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                          defaultValue="+44"
                        />
                        <input
                          type="text"
                          className="px-[15px] h-[52px] w-full border border-[#E0E4EC]  bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                          placeholder="Please enter your phone number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              }
              {activeTab === 1 &&
                <div>
                  Login and Security
                </div>
              }
              {activeTab === 2 &&
                <div>
                  Notifications
                </div>
              }
            </div>
          </div>

          <div className='mt-[100px]'>
            <Link className='bg-[#007AFF] h-[50px] text-[18px] font-bold text-[#fff] inline-block px-[20px] leading-[50px] rounded-[8px]' to=''>Mettre à jour le profil</Link>
            <Link className='bgtext-[18px] font-medium text-[#4C535F] inline-block  ml-[54px]' to=''>Mettre à jour le profil</Link>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Profile
