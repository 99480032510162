import { useState } from 'react';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { RiCloseLargeLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';

const UsesCases = () => {
  const [isOpen, setIsOpen] = useState(false)

  const togglePopup = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Layout>
        <div>
          <h1 className='mb-[25px]'>Uses cases</h1>
          <h3>Select a use case and ......</h3>

          <div className='grid grid-cols-3 gap-[20px] mt-[45px]'>
            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Perform an ESG due  Diligence with the  Regulation</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score </span>
            </button>

            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Perform an ESG Compliance check</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score</span>
            </button>

            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Dashboard from the ESG pillars and all the  indicators and sub indicators</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score </span>
            </button>
            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Perform an ESG due  Diligence with the  Regulation</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score </span>
            </button>

            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Perform an ESG Compliance check</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score</span>
            </button>

            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Dashboard from the ESG pillars and all the  indicators and sub indicators</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score </span>
            </button>
            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Perform an ESG due  Diligence with the  Regulation</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score </span>
            </button>

            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Perform an ESG Compliance check</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score</span>
            </button>

            <button onClick={togglePopup} className='p-[30px] group text-white duration-500 bg-[#31587217] rounded-[20px] hover:bg-[#2175BF] text-left'>
              <strong className='text-[17px] group-hover:text-white duration-500 font-semibold text-[#1B2228] mb-[15px] w-full fontFamilyOutfit line-clamp-2'>Dashboard from the ESG pillars and all the  indicators and sub indicators</strong>
              <span className='text-[13px] group-hover:text-white duration-500 font-medium text-[#16527A] fontFamilyOutfit'>The model returns a visualization AND, report and a score </span>
            </button>
          </div>
        </div>

        {isOpen && (
          <div
            className='fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50'
            onClick={togglePopup}
          >
            <div
              className='bg-white lg:ml-[265px] min-w-[625px] w-full rounded-lg shadow-lg max-w-sm transform transition-transform duration-300 ease-in-out scale-0'
              onClick={e => e.stopPropagation()} // Prevent overlay click
              style={{
                transform: isOpen ? 'scale(1)' : 'scale(0)',
                transition: 'transform 0.3s ease-in-out'
              }}
            >
              <div className='px-[20px] py-[10px] flex items-center justify-between gap-[10px]'>
                <h4 className='font-medium mb-0 text-[22px] fontFamilyOutfit'>Perform an ESG due Diligence With the regulation</h4>

                <button onClick={togglePopup} >
                  <RiCloseLargeLine className='text-[20px] text-[#000]' />
                </button>
              </div>

              <div className='px-[20px] py-[45px] bg-[#F7F8FA] border-y border-[#EBEEFA]'>
                <p className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>Description de l’use case pour explication à l’utilisateur comment ça fonctionneThe model returns a visualization AND, report and a score </p>
                <p className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>Description de l’use case pour explication à l’utilisateur comment ça fonctionneThe model returns a visualization AND, report and a score </p>
                <p className='mb-0 text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>Description de l’use case pour explication à l’utilisateur comment ça fonctionneThe model returns a visualization AND, report and a score </p>
              </div>

              <div className='px-[20px] py-[10px] flex items-center justify-between gap-[30px]'>
                <button onClick={togglePopup} className='py-[10px] border border-[#246176] px-[20px] w-full bg-[#FF3B30]/50 text-[#FF3B30] font-semibold text-[15px] rounded-[6px]'>Cancel</button>
                <Link to='/informations' className='py-[10px] text-center border border-[#246176] px-[20px] w-full bg-[#246176] text-[#fff] font-semibold text-[15px] rounded-[6px]'>Continue</Link>
              </div>
            </div>
          </div>
        )}

        <div className='flex items-center justify-between gap-2 mt-[100px] mb-[20px]'>
          <span className='text-[#4763E4] text-[16px]'>12 uses cases</span>

          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" >
            <a href="/" className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <IoIosArrowBack />
            </a>
            <a href="/" className="relative z-10 inline-flex items-center bg-[#4763E4] px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">1</a>
            <a href="/" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">2</a>
            <a href="/" className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">3</a>
            <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0">...</span>
            <a href="/" className="relative hidden items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 md:inline-flex">8</a>
            <a href="/" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">9</a>
            <a href="/" className="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">10</a>
            <a href="/" className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
              <IoIosArrowForward />
            </a>
          </nav>
        </div>

      </Layout>
    </>
  )
}

export default UsesCases
