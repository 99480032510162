import { useState } from 'react'
import { IoMdNotificationsOutline } from 'react-icons/io'
import { MdMenu, MdMenuOpen } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { ImageIcons } from '../imageComponent'

interface HeaderProps {
  ToggleClick: () => void;
}

const Header = ({ ToggleClick }: HeaderProps) => {
  const [toggleClass, settoggleClass] = useState(false)

  const toggleonClick = () => {
    settoggleClass(!toggleClass)
  }

  return (
    <header className={` bg-[#edf2f6] z-10 text-[#171718] py-[20px] px-[40px] flex justify-between items-center sticky top-0 right-0 ${toggleClass ? '' : 'max-lg:before:content max-lg:before:absolute max-lg:before:top-0 max-lg:before:left-0 max-lg:before:h-full max-lg:before:w-full max-lg:before:bg-[#e7e7e7]'}`}>
      <div
        className='relative z-30'
        onClick={() => {
          ToggleClick()
          toggleonClick()
        }}
      >
        {toggleClass ? (
          <MdMenu className='text-[28px] cursor-pointer' />
        ) : (
          <MdMenuOpen className='text-[28px] cursor-pointer' />
        )}
      </div>
      <div className={` flex items-center gap-[25px] ${toggleClass ? '' : 'max-sm:hidden'}`}>
        <Link to='' className='flex items-center gap-[10px]'>
          <span className='w-[40px] h-[40px] rounded-full overflow-hidden'>
            <img
              src={ImageIcons.profile}
              alt=''
              className='w-full h-full object-cover'
            />
          </span>
          <div>
            <span className='block font-medium text-[#1B2228] dark:text-white'>
              Tim Cook
            </span>
            <span className='block text-[#1B2228] text-[14px]'>timcook@force.com</span>
          </div>
        </Link>

        <Link to='#'>
          <IoMdNotificationsOutline className='text-[24px]' />
        </Link>
      </div>
    </header>
  )
}

export default Header
