import { AiOutlineEdit } from "react-icons/ai";
import { BsFillSendFill } from "react-icons/bs";
import { GrFormPin, GrMicrophone } from "react-icons/gr";
import { ImageIcons } from '../components/imageComponent';
import Layout from '../components/shared/Layout';


const Reponses = () => {

  return (
    <>
      <Layout>
        <div className="pt-[20px]">
          <div className="max-w-[800px] mx-auto relative">
            <div className="flex justify-end items-end text-right gap-[15px] mb-[30px]">
              <div>
                <span className="text-[14px] text-[#888888]">2.03 PM, 15 Nov</span>
                <p className="text-[#888888] text-[18px] mb-0">Climatology development, UX/UI, product design - what is this?</p>
              </div>
              <div className='min-w-[61px]'>
                <button className='text-[#888888] text-[25px] hover:text-gray-900 duration-500 mr-[12px]'><AiOutlineEdit /></button>
                <button className='text-[#888888] text-[25px] hover:text-gray-900 duration-500'><GrFormPin /></button>
              </div>
            </div>

            <div className="flex gap-[15px] mb-[30px]">
              <div className='min-w-[25px]'>
                <img src={ImageIcons.chat_GTP} alt='chat GTP' />
              </div>
              <div>
                <span className="text-[14px] text-[#000] mb-[10px] inline-block">2.03 PM, 15 Nov</span>
                <p className="text-[#000] text-[18px]">Climatology development, UX/UI, and product design are all related terms in the field of climatology, but they refer to slightly different aspects of the climatology discussion.</p>
                <p className="text-[#000] text-[18px]">Climatology development refers...</p>
              </div>
            </div>



            <div className="w-full bg-[#fbfdfe] fixed max-w-[800px] bottom-0 pt-[15px] pb-[20px]">
              <div className="flex justify-between">
                <div className="flex gap-[12px]">
                  <button className="h-[30px] rounded-[8px] px-[12px] bg-[#EEEEEE] flex items-center justify-center gap-[10px]">
                    <img src={ImageIcons.translate} alt='translate' />
                    <span className='text-[#585858] text-[14px]'>Translate</span>
                  </button>
                  <button className="h-[30px] rounded-[8px] px-[12px] bg-[#EEEEEE] flex items-center justify-center gap-[10px]">
                    <img src={ImageIcons.improve} alt='Improve' />
                    <span className='text-[#585858] text-[14px]'>Improve</span>
                  </button>
                  <button className="h-[30px] rounded-[8px] px-[12px] bg-[#EEEEEE] flex items-center justify-center gap-[10px]">
                    <img src={ImageIcons.makeLonger} alt='Make longer' />
                    <span className='text-[#585858] text-[14px]'>Make longer</span>
                  </button>
                  <button className="h-[30px] rounded-[8px] px-[12px] bg-[#EEEEEE] flex items-center justify-center gap-[10px]">
                    <img src={ImageIcons.makeShorter} alt='Make shorter' />
                    <span className='text-[#585858] text-[14px]'>Make shorter</span>
                  </button>



                </div>

                <button className="text-sm text-gray-600 flex items-center gap-1 hover:text-gray-900">
                  <img src={ImageIcons.chatClear} alt='Chat' />
                  New dialog
                </button>
              </div>

              <div className="flex items-center mt-4 border rounded-[8px] px-[15px]">
                <button className="text-[#919191] hover:text-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.586-6.586a4 4 0 10-5.656-5.656L7 10.344a6 6 0 108.486 8.486l4.95-4.95" />
                  </svg>
                </button>
                <input type="text" placeholder="Send a message" className="flex-1 h-[44px] ml-3 outline-none border-none text-gray-700 placeholder-gray-400 bg-transparent" />
                <div>
                  <button className="text919191] hover:text-gray-700">
                    <GrMicrophone />
                  </button>
                  <button className="text-[#000] ml-[10px] hover:text-gray-700">
                    <BsFillSendFill />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Reponses;
