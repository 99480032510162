import Slider from "react-slick";
import { ImageIcons } from '../imageComponent';

const LogoSlider = () => {
    let settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        autoplay: true,
        autoplaySpeed: 8000,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <section className="bg-[#030334]">
            <div className="container">
                <Slider {...settings}>
                    {data.map((item, i) => (
                        <div key={i} >
                            <img src={item.img} alt={item.imgAlt} className='mx-auto' />
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

const data = [
    {
        img: ImageIcons.logo1,
        imgAlt: "logo 1",
    },
    {
        img: ImageIcons.logo2,
        imgAlt: "logo 2",
    },
    {
        img: ImageIcons.logo3,
        imgAlt: "logo 3",
    },
    {
        img: ImageIcons.logo4,
        imgAlt: "logo 4",
    },
    // {
    //     img: logo5,
    //     imgAlt: "logo 5",
    // },

]

export default LogoSlider;
