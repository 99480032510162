import React from "react";
import { ImageIcons } from "../components/imageComponent";

const PasswordVerification = () => {

  return (
    <section>
      <div className="min-w-screen min-h-screen form-bg flex items-center justify-center px-5 py-5 bg-cover bg-center" style={{ backgroundImage: `url(${ImageIcons.formBgImg})` }}>
      <div className="bg-white rounded-lg shadow-xl w-full overflow-hidden" style={{ maxWidth: "980px" }}>
            <div className="md:flex w-full items-center">
              <div className="hidden md:block w-1/2 py-12 px-12">
                <img src={ImageIcons.logoLarge} alt="Logo" />
              </div>
              <div className="w-full md:w-1/2 py-12 px-12 md:px-10 border-[#221C4880] border-l min-h-[360px] flex items-center">
                <div className="text-left pb-7">
                  <h1 className="relative text-[28px] font-normal text-[#000] before:content-[''] before:absolute before:left-0 before:top-0 before:bottom-0 before:bg-[#16A34A] before:w-[8px] pl-5">Email address verification email, containing the password reset link.</h1>
                </div>
              </div>
            </div>
          </div>
      </div>
    </section>
  )
}

export default PasswordVerification