import { BsFillSendFill } from "react-icons/bs";
import { FiArrowRight } from 'react-icons/fi';
import { GrMicrophone } from "react-icons/gr";
import { Link } from 'react-router-dom';
import { ImageIcons } from '../components/imageComponent';
import Layout from '../components/shared/Layout';


const ChatBot = () => {

  return (
    <>
      <Layout>
        <div className="pt-[20px]">
          <div className="min-h-screen max-w-[800px] mx-auto relative">
            <div className="text-center mb-[50px]">
              <h1 className="text-4xl font-bold text-gray-900">Chat Bot</h1>
              <p className="text-gray-500 mt-2">Ver 4.0 Mar 14</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-[24px] max-w-6xl w-full">
              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <span className='w-[50px] h-[50px] rounded-full mb-[20px] flex items-center justify-center bg-[#F8F8F8]'>
                  <img src={ImageIcons.sparkles} alt='' />
                </span>
                <h4 className="font-medium mb-[12px]">Examples</h4>
                <ul className="space-y-4 text-gray-600">
                  <Link to='/reponses' className="text-[#919191] text-[14px] underline">
                    Explain quantum computing in simple terms <FiArrowRight className="inline-block" />
                  </Link>
                </ul>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <span className='w-[50px] h-[50px] rounded-full mb-[20px] flex items-center justify-center bg-[#F8F8F8]'>
                  <img src={ImageIcons.star} alt='' />
                </span>
                <h4 className="font-medium mb-[12px]">Capabilities</h4>
                <p className="text-[#919191] text-[14px] mb-0">
                  Remembers what user said earlier in the conversation
                </p>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <span className='w-[50px] h-[50px] rounded-full mb-[20px] flex items-center justify-center bg-[#F8F8F8]'>
                  <img src={ImageIcons.exclamation} alt='' />
                </span>
                <h4 className="font-medium mb-[12px]">Limitations</h4>
                <p className="text-[#919191] text-[14px] mb-0">
                  Explain quantum computing in simple terms
                </p>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <ul className="space-y-4 text-gray-600">
                  <Link to='' className="text-[#919191] text-[14px] underline">
                    Got any creative ideas for a 10 year old’s birthday? <FiArrowRight className="inline-block" />
                  </Link>
                </ul>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <p className="text-[#919191] text-[14px] mb-0">Allows user to provide follow-up corrections</p>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <p className="text-[#919191] text-[14px] mb-0">May occasionally produce harmful instructions or biased</p>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <ul className="space-y-4 text-gray-600">
                  <Link to='' className="text-[#919191] text-[14px] underline">
                    How do I make an HTTP request in Javascript? <FiArrowRight className="inline-block" />
                  </Link>
                </ul>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <p className="text-[#919191] text-[14px] mb-0">Trained to decline inappropriate requests</p>
              </div>

              <div className="bg-white rounded-[12px] border-2 border-[#E7E7E7] p-[24px] ">
                <p className="text-[#919191] text-[14px] mb-0">Limited knowledge of world and events after 2021</p>
              </div>
            </div>

            <div className="w-full bg-[#fbfdfe] fixed max-w-[800px] bottom-0 pt-[15px] pb-[20px]">
              <div className="flex justify-end">
                <button className="text-sm text-gray-600 flex items-center gap-1 hover:text-gray-900">
                  <img src={ImageIcons.chatClear} alt='Chat' />
                  New dialog
                </button>
              </div>

              <div className="flex items-center mt-4 border rounded-[8px] px-[15px]">
                <button className="text-[#919191] hover:text-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.586-6.586a4 4 0 10-5.656-5.656L7 10.344a6 6 0 108.486 8.486l4.95-4.95" />
                  </svg>
                </button>
                <input type="text" placeholder="Send a message" className="flex-1 h-[44px] ml-3 outline-none border-none text-gray-700 placeholder-gray-400 bg-transparent" />
                <div>
                  <button className="text919191] hover:text-gray-700">
                    <GrMicrophone />
                  </button>
                  <button className="text-[#000] ml-[10px] hover:text-gray-700">
                    <BsFillSendFill />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ChatBot;
