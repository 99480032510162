import Banner from '../components/home/Banner'
import Benefits from '../components/home/Benefits'
import Features from '../components/home/Features'
import LogoSlider from '../components/home/LogoSlider'
import Pricing from '../components/home/Pricing'
import Testimonial from '../components/home/Testimonial'
import HomeLayout from '../components/shared/HomeLayout'

const Home = () => {
  return (
    <HomeLayout>
      <Banner />
      <Features />
      <Benefits />
      <LogoSlider />
      <Testimonial />
      <Pricing />
    </HomeLayout>
  )
}

export default Home
