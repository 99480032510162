import chatBot from "../../assets/icons/chat_bot.svg";
import chatClear from "../../assets/icons/chat_clear.svg";
import chat_GTP from "../../assets/icons/chat_gtp.svg";
import dashboard from "../../assets/icons/dashboard.svg";
import esgRapport from "../../assets/icons/esg_rapport.svg";
import exclamation from "../../assets/icons/exclamation.svg";
import imgUpload from "../../assets/icons/img_upload.svg";
import improve from "../../assets/icons/improve.svg";
import makeLonger from "../../assets/icons/make-longer.svg";
import makeShorter from "../../assets/icons/make-shorter.svg";
import profile from "../../assets/icons/profile.png";
import sparkles from "../../assets/icons/sparkles.svg";
import star from "../../assets/icons/star.svg";
import translate from "../../assets/icons/translate.svg";
import usesCases from "../../assets/icons/uses_cases.svg";
import formBgImg from "../../assets/images/form_bg_img.jpg";
import logo from "../../assets/images/logo.svg";
import logoLarge from "../../assets/images/logo_large.png";

import facebook from "../../assets/icons/face-book.svg";
import instagram from "../../assets/icons/instagram.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import twitter from "../../assets/icons/twitter.svg";
import youtube from "../../assets/icons/youtube.svg";
import footerLogo from "../../assets/images/footer_logo.svg";

import banner from "../../assets/images/banner.png";
import client1 from "../../assets/images/client1.png";
import client2 from "../../assets/images/client2.png";
import client3 from "../../assets/images/client3.png";

import chartAverage from "../../assets/images/chart-average.svg";
import transferDiagonal from "../../assets/images/circle-arrow-data-transfer-diagonal.svg";
import lockKey from "../../assets/images/lock-key.svg";
import setup from "../../assets/images/setup.svg";
import shareKnowledge from "../../assets/images/share-knowledge.svg";
import timeHalfPass from "../../assets/images/time-half-pass.svg";

import arrowUpRight from "../../assets/icons/arrow-up-right.png";
import chartLineData from "../../assets/icons/chart-line-data.png";
import dashboardSquare from "../../assets/icons/dashboard-square.png";
import foldeDetails from "../../assets/icons/folder-details.png";
import share from "../../assets/icons/share.png";

import logo1 from "../../assets/images/logo1.svg";
import logo2 from "../../assets/images/logo2.svg";
import logo3 from "../../assets/images/logo3.svg";
import logo4 from "../../assets/images/logo4.svg";

type ImageIconsType = {
    [key: string]: string;
};

const ImageIcons: ImageIconsType = {
    banner, client1, client2, client3,
    chartAverage, transferDiagonal, lockKey, setup, timeHalfPass, shareKnowledge,
    chartLineData, dashboardSquare, foldeDetails, share, arrowUpRight,
    logo1, logo2, logo3, logo4,
    facebook,
    instagram,
    twitter,
    linkedin,
    youtube,
    footerLogo,
    profile,
    logo,
    chatBot,
    dashboard,
    usesCases,
    esgRapport,
    imgUpload,
    logoLarge,
    formBgImg,
    star,
    exclamation,
    sparkles,
    chatClear,
    translate,
    improve,
    makeLonger,
    makeShorter,
    chat_GTP
}

export default ImageIcons;