

const Testimonial = () => {
    return (
        <section className="">
            <div className="container">
                Testimonial
            </div>
        </section>
    );
};



export default Testimonial;
