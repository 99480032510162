import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Bar, BarChart, } from 'recharts';
import Layout from '../components/shared/Layout';

import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const data = [
  { name: 'Oct', value: 400 },
  { name: 'May', value: 600 },
  { name: 'Jan', value: 800 },
  { name: 'Feb', value: 1000 },
  { name: 'July', value: 1200 },
  { name: 'Aug', value: 1500 }
]

const esgData = [
  { name: 'Jan', uv: 400, pv: 240, amt: 2400 },
  { name: 'Feb', uv: 300, pv: 456, amt: 2200 },
  { name: 'Mar', uv: 200, pv: 139, amt: 2000 },
  { name: 'Apr', uv: 278, pv: 390, amt: 2100 },
  { name: 'May', uv: 189, pv: 480, amt: 2500 },
  { name: 'Jun', uv: 239, pv: 380, amt: 2100 },
  { name: 'Jul', uv: 349, pv: 430, amt: 2300 },
];

const Dashboard = () => {
  return (
    <>
      <Layout>
        <h1 className='mb-[20px]'>Dashboard</h1>
        <h3 className='fontFamilyDomine'>Key Metrics</h3>

        <div className='grid grid-cols-5 gap-[25px] mb-[30px]'>
          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>Total Companies</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>1,234</div>
            <span className='text-[#16527A] text-[13px] fontFamilyOutfit font-medium'>+3%</span>
          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>Average ESG Score</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>75</div>
            <span className='text-[#16527A] text-[13px] fontFamilyOutfit font-medium'>+1%</span>
          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>High Risk Companies</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>56</div>
            <span className='text-[#16527A] text-[13px] fontFamilyOutfit font-medium'>+2%</span>
          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>New Data Entries</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>890</div>
            <span className='text-[#16527A] text-[13px] fontFamilyOutfit font-medium'>+5%</span>
          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>Top Performing Companies</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>450</div>
            <span className='text-[#16527A] text-[13px] fontFamilyOutfit font-medium'>+7%</span>
          </div>
        </div>

        <h3>ESG Score Trends</h3>

        <div className='grid grid-cols-3 gap-[25px]'>
          <div className='bg-[#31587217] rounded-[20px] p-[30px] max-h-[500px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>ESG Score</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>75 <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+1%</span></div>
            <div className="mt-[30px]" style={{ width: '100%', height: '200px' }}>
              <ResponsiveContainer>
                <AreaChart
                  data={esgData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} />
                  <YAxis tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Area type="monotone" dataKey="uv" stroke="#FBFDFE00" fill="#2488CB" />
                </AreaChart>
              </ResponsiveContainer>
            </div>


          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>New Data Entries</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>890 <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+5%</span></div>

            <div className='mt-[30px]' style={{ width: '100%', height: '200px' }}>
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="2 2" vertical={false} />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} />
                  <YAxis tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Bar dataKey="value" fill="#2488cb" barSize={32} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>New Data Entries</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>890 <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+5%</span></div>

            <div className='mt-[30px]' style={{ width: '100%', height: '200px' }}>
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="2 2" vertical={false} />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} />
                  <YAxis tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Bar dataKey="value" fill="#2488cb" barSize={32} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Dashboard
