import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import { ImageIcons } from "../components/imageComponent";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    return (
        <section>
            <div className="min-w-screen min-h-screen form-bg flex items-center justify-center px-5 py-5 bg-cover bg-center" style={{ backgroundImage: `url(${ImageIcons.formBgImg})` }}>
                <div className="bg-white rounded-lg shadow-xl w-full overflow-hidden" style={{ maxWidth: "980px" }}>
                    <div className="md:flex w-full items-center">
                        <div className="hidden md:block w-1/2 py-12 px-12">
                            <img src={ImageIcons.logoLarge} alt="Logo" />
                        </div>
                        <div className="w-full md:w-1/2 py-12 px-12 md:px-10 border-[#221C4880] border-l">
                            <div className="text-center pb-[10px]">
                                <h1 className="text-[28px]  font-normal text-[#2C226D]">Connection</h1>
                                <p className="text-[20px] text-black">Please enter your login information</p>
                            </div>
                            <div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-5">
                                        <label htmlFor="email" className="text-[16px] font-normal text-black px-1 inline-block mb-[6px]">Email</label>
                                        <div className="flex">
                                            <input type="email" id="email" className="w-full px-[15px] h-[50px] rounded-[8px] text-black border-[1px] border-[#ddd] outline-none focus:shadow-none" placeholder="johndoe@gmail.com" />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex -mx-3">
                                    <div className="w-full px-3 mb-3">
                                        <label htmlFor="password" className="text-[16px] font-normal text-black px-1 inline-block mb-[6px]">Password</label>
                                        <div className="relative flex">
                                            <input type={showPassword ? 'text' : 'password'} className="w-full px-[15px] h-[50px] rounded-[8px] text-black border-[1px] border-[#ddd] outline-none focus:shadow-none" placeholder="****************" />
                                            <button
                                                type="button"
                                                onClick={togglePassword}
                                                className="absolute right-3 top-1/2 transform -translate-y-1/2" >
                                                {showPassword ? (
                                                    <FaEyeSlash className="text-black" size={16} />
                                                ) : (
                                                    <FaEye className="text-black" size={16} />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='text-center mb-3'><Link className='text-[#000] text-[16px] hover:text-[#0D99FF]' to='/reset-password'>Forgotten password?</Link></div>
                                <Link to='/dashboard' className="w-full px-[15px] h-[50px] bg-[#0D99FF] font-normal text-white rounded-[8px] flex items-center justify-center text-[20px] hover:bg-black transition-all focus:outline-none">
                                    Log In <MdOutlineArrowRightAlt className=" text-[20px] h-[32px] w-[32px] pt-[4px]" />
                                </Link>
                                <div className='text-center mt-3'><p className='text-[#000] text-[17px] inline-block mr-1'>Don't have an account?</p><Link className="hover:text-[#0D99FF]" to='/sign-up'>Create an account</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login