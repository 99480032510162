import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { Bar, BarChart, } from 'recharts';
import Layout from '../components/shared/Layout';

import { Link } from 'react-router-dom';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const data = [
  { name: 'Oct', value: 400 },
  { name: 'May', value: 600 },
  { name: 'Jan', value: 800 },
  { name: 'Feb', value: 1000 },
  { name: 'July', value: 1200 },
  { name: 'Aug', value: 1500 }
]

const esgData = [
  { name: 'Jan', uv: 400, pv: 240, amt: 2400 },
  { name: 'Feb', uv: 300, pv: 456, amt: 2200 },
  { name: 'Mar', uv: 200, pv: 139, amt: 2000 },
  { name: 'Apr', uv: 278, pv: 390, amt: 2100 },
  { name: 'May', uv: 189, pv: 480, amt: 2500 },
  { name: 'Jun', uv: 239, pv: 380, amt: 2100 },
  { name: 'Jul', uv: 349, pv: 430, amt: 2300 },
];

const Results = () => {
  return (
    <>
      <Layout>
        <div className='flex justify-between gap-[30px]'>
          <div className='flex gap-[34px]'>
            <h1 className='mb-[20px]'>Results</h1>
            <h3 className='fontFamilyDomine font-bold leading-[24px]'>
              Name of the  Firm ;<br />
              Location ;<br />
              Place of  Operation (ZIP Code);<br />
              sector of operation;<br />
              GPS  Code,
            </h3>
          </div>

          <Link
            className="bg-[#007AFF] mt-[15px] text-center h-[50px] text-[18px] text-[#fff] inline-block px-[50px] leading-[50px] rounded-[8px]"
            to=""
          >
            Generate report
          </Link>
        </div>

        <div className='grid grid-cols-3 gap-[25px] mb-[25px]'>
          <div className='bg-[#31587217] rounded-[20px] p-[30px] max-h-[500px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>ESG Score</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>75 <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+1%</span></div>
            <div className="mt-[30px]" style={{ width: '100%', height: '200px' }}>
              <ResponsiveContainer>
                <AreaChart
                  data={esgData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} />
                  <YAxis tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Area type="monotone" dataKey="uv" stroke="#FBFDFE00" fill="#2488CB" />
                </AreaChart>
              </ResponsiveContainer>
            </div>


          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>New Data Entries</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>890 <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+5%</span></div>

            <div className='mt-[30px]' style={{ width: '100%', height: '200px' }}>
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="2 2" vertical={false} />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} />
                  <YAxis tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Bar dataKey="value" fill="#2488cb" barSize={32} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>New Data Entries</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>890 <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+5%</span></div>

            <div className='mt-[30px]' style={{ width: '100%', height: '200px' }}>
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="2 2" vertical={false} />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} />
                  <YAxis tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Bar dataKey="value" fill="#2488cb" barSize={32} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <h3 className='fontFamilyDomine font-bold'>Risk Analysis</h3>

        <div className='grid grid-cols-2 gap-[25px] mb-[25px]'>
          <div className='bg-[#31587217] rounded-[20px] p-[30px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>Card title</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>12.4k <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+10.7% last mo</span></div>

            <div
              className="mt-[30px]"
              style={{ width: "100%", height: "200px" }}
            >
              <ResponsiveContainer>
                <BarChart
                  data={data}
                  layout="vertical"
                  margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="2 2" horizontal={false} />
                  <XAxis type="number" tickLine={false} axisLine={false} />
                  <YAxis
                    type="category"
                    dataKey="name"
                    tickLine={false}
                    axisLine={false}
                  />
                  <Tooltip />
                  <Bar dataKey="value" fill="#2488cb" barSize={32} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>

          <div className='bg-[#31587217] rounded-[20px] p-[30px] max-h-[500px]'>
            <div className='flex items-center justify-between gap-[8px] mb-[12px]'>
              <h5 className='fontFamilyOutfit text-[#1B2228] mb-0 line-clamp-1'>New Data Entries</h5>
              <span className='min-w-[25px] inline-block text-[26px]'>
                <HiOutlineDotsHorizontal />
              </span>
            </div>
            <div className='text-[34px] font-bold fontFamilyDomine text-[#1B2228]'>890 <span className='text-[#16527A] text-[13px] font-medium fontFamilyOutfit'>+5%</span></div>
            <div className="mt-[30px]" style={{ width: '100%', height: '200px' }}>
              <ResponsiveContainer>
                <AreaChart
                  data={esgData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tickLine={false} axisLine={false} />
                  <YAxis tickLine={false} axisLine={false} />
                  <Tooltip />
                  <Area type="monotone" dataKey="uv" stroke="#FBFDFE00" fill="#2488CB" />
                </AreaChart>
              </ResponsiveContainer>
            </div>


          </div>
        </div>
      </Layout>
    </>
  )
}

export default Results
