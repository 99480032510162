import { MdOutlineArrowRightAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import { ImageIcons } from "../components/imageComponent";

const ResetPassword = () => {

  return (
    <section>
      <div className="min-w-screen min-h-screen form-bg flex items-center justify-center px-5 py-5 bg-cover bg-center" style={{ backgroundImage: `url(${ImageIcons.formBgImg})` }}>
        <div className="bg-white rounded-lg shadow-xl w-full overflow-hidden" style={{ maxWidth: "980px" }}>
          <div className="md:flex w-full items-center">
            <div className="hidden md:block w-1/2 py-12 px-12">
              <img src={ImageIcons.logoLarge} alt="Logo" />
            </div>
            <div className="w-full md:w-1/2 py-12 px-12 md:px-10 border-[#221C4880] border-l">
              <div className="text-center pb-[10px]">
                <h1 className="text-[28px]  font-normal text-[#2C226D]">Forgotten password</h1>
              </div>
              <div>
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-5">
                    <label htmlFor="email" className="text-[16px] font-normal text-black px-1 inline-block mb-[6px]">Email Address</label>
                    <div className="flex">
                      <input type="email" id="email" className="w-full px-[15px] h-[50px] rounded-[8px] text-black border-[1px] border-[#ddd] outline-none focus:shadow-none" placeholder="force@emailaddress.com" />
                    </div>
                  </div>
                </div>
                <Link to='/password-verification' className="w-full px-[15px] h-[50px] bg-[#0D99FF] font-normal text-white rounded-[8px] flex items-center justify-center text-[20px] hover:bg-black transition-all focus:outline-none">
                  Log In <MdOutlineArrowRightAlt className=" text-[20px] h-[32px] w-[32px] pt-[4px]" />
                </Link>
                <div className='text-center mt-3'><Link className='hover:text-[#000] text-[#0D99FF] text-[17px]' to='/login'>Back to login page</Link></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ResetPassword