import { ImageIcons } from '../imageComponent';

const Features = () => {
    return (
        <section className="bg-[#030334]">
            <div className="container">
                <div className="text-center">
                    <div className="md:mb-[50px] mb-[40px] border-b max-w-[400px] mx-auto border-[#9CA3AF]">
                        <h2 className="text-white font-bold mb-[10px]">Key Features</h2>
                        <p className="mb-[12px] text-[#D1D5DB]">
                            Powerful Features to Elevate Your Business
                        </p>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-[15px]">
                        <FeatureCard
                            imgSrc={ImageIcons.chartLineData}
                            title="ESG Due Diligence"
                            description="Conduct thorough ESG compliance checks."
                        />
                        <FeatureCard
                            imgSrc={ImageIcons.dashboardSquare}
                            title="Real-Time Data Tracking"
                            description="Monitor performance across environmental, social, and governance pillars."
                        />
                        <FeatureCard
                            imgSrc={ImageIcons.foldeDetails}
                            title="Risk Assessment Tools"
                            description="Evaluate climate-related and sustainability risks."
                        />
                        <FeatureCard
                            imgSrc={ImageIcons.share}
                            title="Predictive & Prescriptive Analytics"
                            description="Leverage machine learning to forecast trends and suggest improvements."
                        />
                    </div>

                    <div className="mt-8">
                        <button className=" border-[0.56px] border-[#6B7280] hover:border-[#007aff] text-white px-4 py-2 rounded-full flex items-center gap-2 hover:bg-[#007aff] mx-auto">
                            <span>Learn More</span>
                            <img className="w-[15px]" src={ImageIcons.arrowUpRight} alt="Arrow icon" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};

interface FeatureCardProps {
    imgSrc: string;
    title: string;
    description: string;
}

const FeatureCard = ({ imgSrc, title, description }: FeatureCardProps) => (
    <div className="p-[15px] bg-[#0C0C44] rounded-lg shadow-md border-[0.56px] border-[#6B7280]">
        <img src={imgSrc} alt={title} className="w-[30px] mb-[30px]" />
        <h3 className="text-lg font-semibold text-white text-left mb-[10px]">{title}</h3>
        <p className="text-sm text-left text-[#9CA3AF]">{description}</p>
    </div>
);

export default Features;
