import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import ChatBot from './pages/ChatBot';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Informations from './pages/Informations';
import Login from './pages/Login';
import NewPassword from './pages/NewPassword';
import PasswordVerification from './pages/PasswordVerification';
import Profile from './pages/Profile';
import RapportEvaluation from './pages/RapportEvaluation';
import Reponses from './pages/Reponses';
import ResetPassword from './pages/ResetPassword';
import Results from './pages/Results';
import SignUp from './pages/SignUp';
import UsesCases from './pages/UsesCases';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/uses-cases" element={<UsesCases />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/password-verification" element={<PasswordVerification />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/informations" element={<Informations />} />
          <Route path="/results" element={<Results />} />
          <Route path="/chat-bot" element={<ChatBot />} />
          <Route path="/reponses" element={<Reponses />} />
          <Route path="/rapport-evaluation" element={<RapportEvaluation />} />
          <Route path="/" element={<Home />} />
          {/* <Route path="/" element={<Navigate to="/login" replace />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App;
