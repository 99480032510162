import React, { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { FiUploadCloud } from "react-icons/fi";
import { Link } from 'react-router-dom';
import Layout from '../components/shared/Layout';


const Informations = () => {
  const [fileName, setFileName] = useState("");

  const [showPassword, setShowPassword] = useState({
    location: false,
    zipCode: false,
    gpsCode: false,
  });

  const togglePasswordVisibility = (field: keyof typeof showPassword) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files[0]) {
      setFileName(files[0].name);
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("fileInput");
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <>
      <Layout>
        <div className="pt-[20px] flex flex-col justify-between">
          <h1 className="mb-[25px]">Perform an ESG due Diligence with the Regulation</h1>
          <h3 className="fontFamilyDomine font-bold">
            The model returns a visualization AND, report and a score
          </h3>
          <div className="grid grid-cols-2 gap-[30px] mt-[30px]">
            {/* Name of the firm */}
            <div>
              <label
                htmlFor="firmName"
                className="block text-[16px] font-medium text-[#4C535F]"
              >
                Name of the firm
              </label>
              <input
                id="firmName"
                type="email"
                className="px-[15px] h-[52px] w-full border border-[#E0E4EC] bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                placeholder="johndoe@gmail.com"
              />
            </div>

            {/* Location */}
            <div>
              <label
                htmlFor="location"
                className="block text-[16px] font-medium text-[#4C535F]"
              >
                Location
              </label>
              <div className='relative'>
                <input
                  id="location"
                  type={showPassword.location ? "text" : "password"}
                  className="px-[15px] h-[52px] w-full border border-[#E0E4EC] bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="********************"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility("location")}
                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                >
                  {showPassword.location ? (
                    <FaEyeSlash className="text-[#4B5563]" size={14} />
                  ) : (
                    <FaEye className="text-[#4B5563]" size={14} />
                  )}
                </button>
              </div>
            </div>

            {/* Place of operation */}
            <div>
              <label
                htmlFor="zipCode"
                className="block text-[16px] font-medium text-[#4C535F]"
              >
                Place of operation (ZIP Code)
              </label>
              <div className="relative">
                <input
                  id="zipCode"
                  type={showPassword.zipCode ? "text" : "password"}
                  className="px-[15px] h-[52px] w-full border border-[#E0E4EC] bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="********************"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility("zipCode")}
                  className="absolute top-1/2 right-3 transform -translate-y-1/2"
                >
                  {showPassword.zipCode ? (
                    <FaEyeSlash className="text-[#4B5563]" size={14} />
                  ) : (
                    <FaEye className="text-[#4B5563]" size={14} />
                  )}
                </button>
              </div>
            </div>

            {/* GPS Code */}
            <div>
              <label
                htmlFor="gpsCode"
                className="block text-[16px] font-medium text-[#4C535F]"
              >
                GPS Code
              </label>
              <div className="relative">
                <input
                  id="gpsCode"
                  type={showPassword.gpsCode ? "text" : "password"}
                  className="px-[15px] h-[52px] w-full border border-[#E0E4EC] bg-[#EDF2F6] rounded-[8px] text-[14px] font-medium text-[#8D98AA] focus:outline-none focus:ring-2 focus:ring-blue-400"
                  placeholder="********************"
                />
                <button
                  type="button"
                  onClick={() => togglePasswordVisibility("gpsCode")}
                  className="absolute right-3 top-1/2 transform -translate-y-1/2"
                >
                  {showPassword.gpsCode ? (
                    <FaEyeSlash className="text-[#4B5563]" size={14} />
                  ) : (
                    <FaEye className="text-[#4B5563]" size={14} />
                  )}
                </button>
              </div>
            </div>

            <div className='col-span-2 bg-white p-[20px] rounded-[12px]'>
              <div className="flex flex-col items-center justify-center w-full h-[170px] border-[1px] border-[#EAECF0] rounded-lg cursor-pointer">
                <input
                  id="fileInput"
                  type="file"
                  accept=".xlsx"
                  className="hidden"
                  onChange={handleFileChange}
                />
                <div onClick={handleClick} className="text-center">
                  <span className='w-[40px] h-[40px] rounded-full bg-[#F2F4F7] border-[6px] border-[#F9FAFB] flex items-center justify-center mx-auto mb-[10px]'>
                    <FiUploadCloud className='text-[#475467] text-[18px]' />
                  </span>
                  <strong className='font-semibold text-[14px] text-[#002E96] mb-[5px]'>Click to upload</strong>
                  <p className="text-gray-500 text-[12px]">XLSX (max 10MB)</p>
                  {fileName && (
                    <p className="mt-2 text-sm text-green-500">File: {fileName}</p>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Link
            className="bg-[#007AFF] mx-auto w-full max-w-[440px] text-center mt-[60px] h-[50px] text-[18px] text-[#fff] inline-block px-[20px] leading-[50px] rounded-[8px]"
            to="/results"
          >
            Go
          </Link>
        </div>
      </Layout>
    </>
  );
};

export default Informations;
