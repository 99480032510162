import Layout from '../components/shared/Layout';

const RapportEvaluation = () => {
  return (
    <>
      <Layout>
        <div className='flex justify-between gap-[30px]'>
          <h1 className='mb-[20px]'>ESG Rapport evaluation</h1>
        </div>

      </Layout>
    </>
  )
}

export default RapportEvaluation
