import { IoIosArrowUp } from 'react-icons/io'
import { Link, useLocation } from 'react-router-dom'
import { ImageIcons } from '../imageComponent'

const Navbar: React.FC = () => {
  const pathname = useLocation().pathname

  return (
    <nav className='dashboard_nav flex flex-col justify-between h-[100vh] w-[265px] sticky top-0 left-0 z-30'>
      <div>
        <div className='p-[20px] bg-[#0e1b6b]'>
          <Link to='/'>
            <img src={ImageIcons.logo} alt='Logo' />
          </Link>
        </div>
        <ul className='p-[20px]'>
          {navMenu.map((item, i) => (
            <li className='mb-[18px]' key={i}>
              <Link to={item.handle} className={` flex items-center justify-between text-[#fff] text-[16px] font-medium opacity-50 ${pathname === item.handle && '!text-[#fff] !opacity-100'
                }`}>
                <div className='flex items-center'>
                  <img src={item.icon} alt={item.iconAlt} className='inline-block pr-[8px]' />
                  <span>{item.link}</span>
                </div>

                <span
                  className={`transition-transform cursor-pointer ${pathname === item.handle && 'rotate-180'
                    }`}
                >
                  <IoIosArrowUp className='text-[20px]' />
                </span>
              </Link>
            </li>
          ))}
        </ul>
      </div>

      <div className='p-[20px]'>
        <ul>
          <li className='mb-[10px]'>
            <Link to='#' className='text-white font-medium'>
              Sign-out
            </Link>
          </li>
          <li>
            <Link to='#' className='text-white font-medium'>
              Settings
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

type NavMenuType = {
  icon: string;
  iconAlt: string;
  link: string;
  handle: string;
}[]

const navMenu: NavMenuType = [
  {
    link: 'Dashboard',
    handle: '/dashboard',
    icon: ImageIcons.dashboard,
    iconAlt: 'Dashboard',
  },
  {
    link: 'Uses cases',
    handle: '/uses-cases',
    icon: ImageIcons.usesCases,
    iconAlt: 'Uses cases',
  },
  {
    link: 'ESG Rapport evaluation',
    handle: '/rapport-evaluation',
    icon: ImageIcons.esgRapport,
    iconAlt: 'ESG Rapport evaluation',
  },
  {
    link: 'Chat bot',
    handle: '/chat-bot',
    icon: ImageIcons.chatBot,
    iconAlt: 'Chat bot',
  }
]

export default Navbar
