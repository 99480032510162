import React, { useState } from "react";
import { MdOutlineArrowRightAlt } from "react-icons/md";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { ImageIcons } from "../components/imageComponent";

const NewPassword = () => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  return (
    <section>
      <div
        className="min-w-screen min-h-screen form-bg flex items-center justify-center px-5 py-5 bg-cover bg-center"
        style={{ backgroundImage: `url(${ImageIcons.formBgImg})` }}
      >
        <div
          className="bg-white rounded-lg shadow-xl w-full overflow-hidden"
          style={{ maxWidth: "980px" }}
        >
          <div className="md:flex w-full items-center">
            <div className="hidden md:block w-1/2 py-12 px-12">
              <img src={ImageIcons.logoLarge} alt="Logo" />
            </div>
            <div className="w-full md:w-1/2 py-12 px-12 md:px-10 border-[#221C4880] border-l">
              <div className="text-center pb-7">
                <h1 className="text-[28px] font-normal text-[#2C226D]">
                  New Password
                </h1>
                <p className="text-[20px] text-black">
                  Please enter a new password
                </p>
              </div>
              <div>
                {/* New Password Field */}
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-3">
                    <label
                      htmlFor="password"
                      className="text-[16px] font-normal text-black px-1 inline-block mb-[6px]"
                    >
                      New Password
                    </label>
                    <div className="relative flex">
                      <input
                        type={showNewPassword ? "text" : "password"}
                        className="w-full px-[15px] h-[50px] text-[14px] rounded-[8px] text-black border-[1px] border-[#E4E4E7] outline-none focus:shadow-none"
                        placeholder="****************"
                      />
                      <button
                        type="button"
                        onClick={toggleNewPassword}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      >
                        {showNewPassword ? (
                          <FaEyeSlash className="text-black" size={16} />
                        ) : (
                          <FaEye className="text-black" size={16} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* Confirm Password Field */}
                <div className="flex -mx-3">
                  <div className="w-full px-3 mb-3">
                    <label
                      htmlFor="confirm-password"
                      className="text-[16px] font-normal text-black px-1 inline-block mb-[6px]"
                    >
                      Confirm your password
                    </label>
                    <div className="relative flex">
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="w-full px-[15px] h-[50px] text-[14px] rounded-[8px] text-black border-[1px] border-[#E4E4E7] outline-none focus:shadow-none"
                        placeholder="****************"
                      />
                      <button
                        type="button"
                        onClick={toggleConfirmPassword}
                        className="absolute right-3 top-1/2 transform -translate-y-1/2"
                      >
                        {showConfirmPassword ? (
                          <FaEyeSlash className="text-black" size={16} />
                        ) : (
                          <FaEye className="text-black" size={16} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* Submit Button */}
                <button className="w-full py-[10px] pr-[10px] pl-[14px] bg-[#0D99FF] font-normal text-white rounded-[8px] flex items-center justify-center text-[20px] hover:bg-black transition-all focus:outline-none">
                  Modify{" "}
                  <MdOutlineArrowRightAlt className=" text-[20px] h-[32px] w-[32px] pt-[4px]" />
                </button>
                <div className="text-center mt-3">
                  <a className="text-[#0D99FF]" href="/">
                    Back to login page
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewPassword;
