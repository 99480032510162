import { useState } from 'react';
import Header from './Header';
import Navbar from './Navbar';

import { ReactNode } from 'react';

const Layout = ({ children }: { children: ReactNode }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const ToggleClick = () => {
    setToggleMenu(!toggleMenu);
  };

  return (
    <>
      <div className='flex'>
        <div className={` duration-500 ${toggleMenu ? ' overflow-hidden invisible visibil ml-[-265px]' : 'ml-0 overflow-visible visible visibil w-[265px]'}`} >
          <Navbar />
        </div>

        <div className={` w-full duration-500 relative ${toggleMenu ? '' : 'max-lg:before:content max-lg:before:absolute max-lg:before:top-0 max-lg:before:left-0 max-lg:before:h-full max-lg:before:w-full max-lg:before:bg-[#e7e7e7]'}`}>
          <Header ToggleClick={ToggleClick} />
          <div className={` py-[20px] px-[40px] ${toggleMenu ? '  ' : 'max-sm:hidden'}`}>
            {children}
          </div>
        </div>
      </div>
    </>
  )
}

export default Layout